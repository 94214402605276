import { Flag } from "./flag.model";


export class FlagsFormResults {
    public id: string;
    public userName: string;
    public blueFlags: string[]; 
    public addedBlueFlags: Flag[];
    public redFlags: string[];
    public addedRedFlags: Flag[];
    public email?: string;

    constructor( id: string, userName: string, blueFlags: string[], addedBlueFlags: Flag[], redFlags: string[], addedRedFlags: Flag[]) {
            this.id = id;
            this.userName = userName; 
            this.addedBlueFlags = addedBlueFlags;
            this.blueFlags = blueFlags;
            this.redFlags = redFlags;
            this.addedRedFlags = addedRedFlags;
    }
}