<div class="container-fluid">
    <div *ngIf="showSectionTwo == false">
        <div class="row">
            <div class="col d-flex justify-content-center">
                <div class="section1">
                    <p class="intro-text">
                        Before we explore <span class="red-flags">Red Flags</span> and <span class="blue-flags">Blue Flags</span>  and how you can use them, 
                        take a minute to look over the list below. As you consider each “flag,” 
                        is it present in your own life? Then, if you’d like, add additional “flags”
                        you think are important to you.
                    </p>
                </div>
            </div>
        </div>
        <div class="row flag-list">
        
                <div class="col">
                    <div class="blue-flags">
                        <p>Blue Flags</p>
                        <div *ngFor="let flag of blueFlagOptions; let i = index">
                            <div class="flags">
                                <ul>
                                    <li>{{ flag.label }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="additional-flags">
                            <p>Additional Blue Flags Important to You:</p>
                        </div>
                        
                        <div>
                            <div *ngFor="let flag of blueFlags; let i = index">
                                <div class="flags">
                                    <ul>
                                        <li>{{ flag }}
                                            <a (click)="onDeleteBlueFlag(flag)">
                                                <i *ngIf="showBlueInput" class="bi bi-x-circle"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <button *ngIf="blueEditMode == false" (click)="blueEditMode = true">Add Blue Flags</button>
                            <div *ngIf="blueEditMode" class="flags">
                                <form (ngSubmit)="onAddBlueFlag(blue)" #blue="ngForm">
                                    <ul>
                                        <li *ngIf="showBlueInput">
                                            <input 
                                                
                                                type="text"
                                                name="flagLabel"
                                                id="i"
                                                ngModel
                                                required>
                                                <button type="submit">Add</button>
                                        </li>
                                    </ul>
                                </form>
                                <button type="button" (click)="onSaveAddedBlue()">{{ blueButtonText }}</button>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div class="col">
                    <div class="red-flags">
                        <p>Red Flags</p>
                        <div *ngFor="let flag of redFlagOptions; let i = index">
                            <div class="flags">
                                <ul>
                                    <li>{{ flag.label }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="additional-flags">
                            <p>Additional Red Flags Important to You:</p>
                        </div>
                        <div>
                            <div *ngFor="let flag of redFlags; let i = index">
                                <div class="flags">
                                    <ul>
                                        <li>{{ flag }}
                                            <a (click)="onDeleteRedFlag(flag)">
                                                <i *ngIf="showRedInput" class="bi bi-x-circle"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <button *ngIf="redEditMode == false" (click)="redEditMode = true">Add Red Flags</button>
                            <div *ngIf="redEditMode" class="flags">
                                <form (ngSubmit)="onAddRedFlag(red)" #red="ngForm">
                                    <ul>
                                        <li *ngIf="showRedInput">
                                            <input 
                                                type="text"
                                                name="flagLabel"
                                                id="i"
                                                ngModel
                                                required>
                                                <button type="submit">Add</button>
                                        </li>
                                    </ul>
                                </form>
                                <button type="button" (click)="onSaveAddedRed()">{{ redButtonText }}</button>
                            </div>
                        </div>
                       
                    </div>
                </div>
            <button (click)="showSectionTwo = true">Next</button>
        </div>
    </div>

        <div id="" class="row">
            <div class="col d-flex justify-content-center">
                <div *ngIf="showSectionTwo" class="section2">
                    <p class="intro-text"> 
                        When couples break up or divorce, many are hurt and angry and bitter. 
                        They point fingers rather than look at themselves. Do you ever wonder why people 
                        end up selecting the wrong match in a long-term relationship?
                    </p>
                    <p class="intro-text">
                        Most people (maybe you?) show the very best of themselves in the early dating phase, 
                        and then gradually change, feeling they’ve “hooked” the person. The conquest is over!
                    </p>
                    <p class="intro-text">
                        Unfortunately, by this time, you’ve allowed yourself to become emotionally involved with 
                        the individual, or even worse, fell in love with that person. Now you are extremely vulnerable 
                        to getting severely hurt by that relationship.
                    </p>
                
                </div>
            </div>
            <div id="next">
                <button (click)="onNext('../hidden-traits')"
                    type="button">
                        Next
                </button>
            </div>
        
        </div>


</div>