import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, NgForm, Validators } from '@angular/forms';
import { Flag } from 'src/app/1_shared/models/flag.model';
import { FlagsService } from 'src/app/1_shared/services/flags.service';

@Component({
  selector: 'app-flags-form',
  templateUrl: './flags-form.component.html',
  styleUrls: ['./flags-form.component.css']
})
export class FlagsFormComponent implements OnInit {
  flagsForm: FormGroup
  flagsArray = [];
  checkedBlueFlags: string[] = [];
  addedBlueFlags: Flag[] = [];
  checkedRedFlags: string[] = [];
  addedRedFlags: Flag[] = [];
  
  constructor(private flagsService: FlagsService) { }

  ngOnInit(): void {
    this.flagsService.blueSelectedSubject.subscribe( 
      checked => {
        this.checkedBlueFlags = checked;
        console.log(this.checkedBlueFlags);
      }
    )
    this.flagsService.blueAddedSubject.subscribe(
      added => {
        this.addedBlueFlags = added;
        console.log(added)
      }

    )
    this.flagsService.redSelectedSubject.subscribe( 
      checked => {
        this.checkedRedFlags = checked;
        console.log(this.checkedRedFlags);
      }
    )
    this.flagsService.redAddedSubject.subscribe(
      added => {
        this.addedRedFlags = added;
        console.log(added)
      }

    )
    this.flagsForm = new FormGroup ({
      'name': new FormControl(null, Validators.required)
      //email
      } )
  }

  onSubmit() {
    let selectedBlueFlags: string[];
    let addBlueFlags: Flag[];
    let selectedRedFlags: string[];
    let addRedFlags: Flag[];

 
    selectedBlueFlags = this.checkedBlueFlags;
    addBlueFlags = this.addedBlueFlags;
    selectedRedFlags = this.checkedRedFlags;
    addRedFlags = this.addedRedFlags;
    this.flagsService.createFlagResults(selectedBlueFlags, addBlueFlags, selectedRedFlags, addRedFlags);
  }

}
