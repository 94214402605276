import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-your-match',
  templateUrl: './your-match.component.html',
  styleUrls: ['./your-match.component.css']
})
export class YourMatchComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  onNext(path: string ) {
    this.router.navigate([path], {relativeTo: this.route})
  }

}
