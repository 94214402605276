<div class="container-fluid">
    <form [formGroup]="flagsForm" (ngSubmit)="onSubmit()" >
        <div>
<!-- name -->
         <div class="row">
            <div class="col">
                <div class="name-el">
                    <div class="form-group">
                        <label for="name">name</label>
                        <input 
                            type="text"
                            class=" l-case"
                            id="name"
                            formControlName="name">
                    </div>
                    <span 
                        *ngIf="!flagsForm.get('name').valid && flagsForm.get('name').touched"
                        class="help-block">
                            Please enter your name!
                    </span>    
                    
                </div>
            </div>
         </div>           
<!-- blue flags --> 
                
            <div class="blue-flags">
                <ol>Blue Flags</ol> 
                <app-blue-flags></app-blue-flags>
            </div>        

<!-- red flags -->
            <div class="red-flags">
                <ol>Red Flags</ol>
                <app-red-flags></app-red-flags>
            </div>
      
 <!-- submit -->
            <button class="btn btn-primary" type="submit">Submit</button>
        </div>
    </form>
</div>

