<div class="navbar">
    <div class="container-fluid">
       
            <a class="navbar-brand" 
                routerLink="/">
                <h1 class="lagos-title">{{ name }}</h1>
            </a> 

           
        </div>
    </div>