<div class="container-fluid">
    <!-- <div class="row">
        <p class="text-center temp">Coming Soon!</p>
    </div> -->
    <div class="row">  
        <div class="box"> 
            <div class="col">
                <div id="item3" class="item">
                    <div >
                        <img id="bio" src="../../assets/graphics/paul_portrait_edit@0.5x.jpg" alt="">
                    </div>
                    <span>
                        Paul Lagomarcino <br>
                        -bio <br>
                        -images <br>
                    </span>
                </div>

                <div id="item4" class="item">
                    About
                </div>
            </div>
            <div class="col">  
            

                <div id="item1" class="item">
                    <div >
                        <img id="workbook" src="../../assets/workbook/1_cover.jpg" alt="">
                    </div>
                    <!-- <span>The Workbook</span> -->
                </div>

       

                <div id="item2" class="item">
                    Finding the Right Match
                </div>


            </div>
           
        </div>
     
    </div>
</div>