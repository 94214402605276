import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-your-flags',
  templateUrl: './your-flags.component.html',
  styleUrls: ['./your-flags.component.css']
})
export class YourFlagsComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  onNext(path: string ) {
    this.router.navigate([path], {relativeTo: this.route})
  }

}
