
<div id="menuBar" class="container-fluid">
    <div class="row">
        <div class="navbar nav">
            <div>
        <!-- Workbook -->
                <button type="button" (click)="navigateTo('workbook')">
                    Book
                </button>
         <!-- Pauls Bio -->
                <button type="button" (click)="navigateTo('paul-lago')">
                    Paul
                </button>
        <!-- Relationships -->
                <button type="button" (click)="navigateTo('relationships')">
                    partners
                </button>
        <!-- Downloads -->
                <button type="button" (click)="navigateTo('download')">
                    Downloads
                    <!-- <i class="bi bi-download"></i> -->
                </button>
            </div>    
        </div>
    </div>
</div>

   <!-- E-mail -->
                <!-- <a href="mailto:?subject=I wanted you to see this site&amp;body=Check out this site http://www.lagos-toolkit.com.">
                    <button type="button">e-mail</button>
                </a>             -->