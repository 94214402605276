import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-five-fingers',
  templateUrl: './five-fingers.component.html',
  styleUrls: ['./five-fingers.component.css'],
  host: { "class" : "router-outlet"}
})
export class FiveFingersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
