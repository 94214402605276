import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FlagsService } from 'src/app/1_shared/services/flags.service';
import { Flag } from 'src/app/1_shared/models/flag.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-flags-intro',
  templateUrl: './flags-intro.component.html',
  styleUrls: ['./flags-intro.component.css']
})
export class FlagsIntroComponent implements OnInit {
  redFlagOptions: Flag[];
  blueFlagOptions: Flag[];
  addedBlueFlags: Flag[] = [];
  addedRedFlags: Flag[] = [];
  redFlags: string[] = [];
  blueFlags: string[] = [];
  showBlueInput: boolean = true;
  showRedInput: boolean = true;
  blueButtonText: string = "Save";
  redButtonText: string = "Save";
  blueEditMode: boolean = false;
  redEditMode: boolean = false;
  showSectionTwo: boolean = false;

  constructor(private flagsService: FlagsService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.redFlagOptions = this.flagsService.redFlagOptions;
    this.blueFlagOptions = this.flagsService.blueFlagOptions;
    this.flagsService.newBlueLabelSubject.subscribe();
    this.flagsService.addedBlueFlagsSub.subscribe(
      flags => {
        this.blueFlags = flags;
      }
    )
    this.flagsService.addedRedFlagsSub.subscribe(
      flags => {
        this.redFlags = flags;
      }
    )
  }

  onNext(path: string ) {
    this.router.navigate([path], {relativeTo: this.route})
  }

  onAddBlueFlag(form: NgForm) {
    const formValue = form.value;
    const label = formValue.flagLabel;
    this.flagsService.newBlueLabelSubject.next(label);
    this.flagsService.putBlueFlag(label);
    form.reset();
  }

  onAddRedFlag(form: NgForm) {
    const formValue = form.value;
    const label = formValue.flagLabel;
    this.flagsService.newRedLabelSubject.next(label);
    this.flagsService.putRedFlag(label);
    form.reset();
  }

  onSaveAddedBlue() {
    if(this.blueButtonText == "Save") {
      this.flagsService.blueAddedSubject.next(this.addedBlueFlags);
      this.showBlueInput = false;
      this.blueButtonText = "Edit";
    } else {
      this.onEditAddedBlue();
    }

    //send to final inventory
  }

  onEditAddedBlue() {
    this.showBlueInput = true;
    this.blueButtonText = "Save";

  }

  onSaveAddedRed() {
    if(this.redButtonText == "Save") {
      this.flagsService.redAddedSubject.next(this.addedRedFlags);
      this.showRedInput = false;
      this.redButtonText = "Edit";
    } else {
      this.onEditAddedRed();
    } 
    //send to final inventory
  }

  onEditAddedRed() {
    this.showRedInput = true;
    this.redButtonText = "Save";

  }


  onDeleteBlueFlag(label: string) {
    this.flagsService.deleteBlueFlag(label);
  }

  onDeleteRedFlag(label: string) {
    this.flagsService.deleteRedFlag(label);
  }
}

