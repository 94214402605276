import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Flag } from 'src/app/1_shared/models/flag.model';
import { FlagsService } from 'src/app/1_shared/services/flags.service';

@Component({
  selector: 'app-red-flags',
  templateUrl: './red-flags.component.html',
  styleUrls: ['./red-flags.component.css']
})
export class RedFlagsComponent implements OnInit {
  redFlagInventory: Flag[] = [];
  redFlags: string[] = [];
  addedRedFlags: Flag[] =[];
  selectArray: string[] = [];

  constructor(private flagsService: FlagsService) { }

  ngOnInit() {
    this.redFlagInventory = this.flagsService.redFlagInventory;
    this.flagsService.addedRedFlagsSub.subscribe(
      flags => {
        this.redFlags = flags;
      }
    )
    
  }

  onAddFlag(form: NgForm) {
    const formValue = form.value;
    const newFlag = new Flag(null, formValue.flagLabel, true);
    this.addedRedFlags.push(newFlag);

    this.flagsService.redAddedSubject.next(this.addedRedFlags);
    console.log(newFlag.label);
    console.log(this.addedRedFlags);
    form.reset();
  }

  onNext(form: NgForm) {
    let checked: string[] = [];
    checked = this.selectArray;
    this.flagsService.redSelectedSubject.next(checked);
    this.flagsService.redAddedSubject.next(this.addedRedFlags);
  }

  onChecked (id: string) {
    if( !this.selectArray.includes(id)) {
      this.selectArray.push(id);
      console.log(this.selectArray)
    } else if( this.selectArray.includes(id)) {
      let i = this.selectArray.indexOf(id);
      let newSelectArray: string[] = [];
      this.selectArray.splice(i, 1);
      newSelectArray = this.selectArray.slice();
      this.selectArray = newSelectArray;
     console.log(this.selectArray);
    }  
    
  }

}
