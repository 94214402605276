<div class="container-fluid">
    <div class="row">
        <nav class="navbar nav">
            <button>download</button>
            <a 
                href="../../../assets/workbook/worksheets/five-fingers/five-fingers.pdf" 
                download="lagos_five-fingers">
                    <i class="bi bi-download"></i>
            </a>
        </nav>
    </div>
    <div class="row">
        Five fingers
        <!-- <img class="hand" src="../../../assets/workbook/worksheets/five-fingers/2_Hand.jpg" alt=""> -->
      
        <div class="option">
            <img src="../../../assets/workbook/worksheets/five-fingers/8_hand 2.jpg" alt="">
        </div>
    </div>
    <div class="row">
        <div class="option">
            <p>1st Finger</p>
            <img src="../../../assets/workbook/pages/11_lagos.jpg" alt="">
            <img src="../../../assets/workbook/pages/12_lagos.jpg" alt="">
        </div>
        <div class="option">
            <p>2nd Finger</p>
            <img src="../../../assets/workbook/pages/13_lagos.jpg" alt="">
            <img src="../../../assets/workbook/pages/14_lagos.jpg" alt="">
        </div>
        <div class="option">
            <p>3rd Finger</p>
            <img src="../../../assets/workbook/pages/15_lagos.jpg" alt="">
            
        </div>
        <div class="option">
            <p>4th Finger</p>
            <img src="../../../assets/workbook/pages/16_lagos.jpg" alt="">
            <img src="../../../assets/workbook/pages/17_lagos.jpg" alt="">
            
        </div>
        <div class="option">
            <p>5th Finger</p>
            <img src="../../../assets/workbook/pages/18_lagos.jpg" alt="">
            <img src="../../../assets/workbook/pages/19_lagos.jpg" alt="">
        </div>
    </div>
</div>
