import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { AnnouncementComponent } from './announcement/announcement.component';
import { SplashComponent } from './splash/splash.component';
import { NavigationComponent } from './navigation/navigation.component';
import { PaulComponent } from './paul/paul.component';
import { RelationshipsComponent } from './relationships/relationships.component';
import { WorkbookComponent } from './workbook/workbook.component';
import { FlagsFormComponent } from './relationships/flags-form/flags-form.component';
import { FooterComponent } from './footer/footer.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { LagosWorkbookComponent } from './workbook/lagos-workbook/lagos-workbook.component';
import { HomeComponent } from './home/home.component';
import { DownloadComponent } from './download/download.component';
import { FiveFingersComponent } from './download/five-fingers/five-fingers.component';
import { BlueFlagsComponent } from './relationships/flags-form/blue-flags/blue-flags.component';
import { RedFlagsComponent } from './relationships/flags-form/red-flags/red-flags.component';
import { ResultsComponent } from './relationships/results/results.component';
import { FlagsIntroComponent } from './relationships/1_flags-intro/flags-intro.component';
import { HiddenTraitsComponent } from './relationships/2_hidden-traits/hidden-traits.component';
import { YourMatchComponent } from './relationships/3_your-match/your-match.component';
import { YourFlagsComponent } from './relationships/4_your-flags/your-flags.component';
import { PartnersFlagsComponent } from './relationships/5_partners-flags/partners-flags.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AnnouncementComponent,
    SplashComponent,
    NavigationComponent,
    PaulComponent,
    RelationshipsComponent,
    WorkbookComponent,
    FlagsFormComponent,
    FooterComponent,
    SideBarComponent,
    LagosWorkbookComponent,
    HomeComponent,
    DownloadComponent,
    FiveFingersComponent,
    BlueFlagsComponent,
    RedFlagsComponent,
    ResultsComponent,
    FlagsIntroComponent,
    HiddenTraitsComponent,
    YourMatchComponent,
    YourFlagsComponent,
    PartnersFlagsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
