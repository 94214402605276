import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-partners-flags',
  templateUrl: './partners-flags.component.html',
  styleUrls: ['./partners-flags.component.css']
})
export class PartnersFlagsComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }
  onNext(path: string ) {
    this.router.navigate([path], {relativeTo: this.route})
  }
}
