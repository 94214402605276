<div class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="header">
                Results
                <div>
                    <p>{{ name }}</p>
                    <p>{{ blueFlagsSelected }}</p>
                    <div *ngFor="item of blueFlagsAdded">
                        <p>{{ item }}</p>
                    </div>
                   
                    <p>{{ redFlagsSelected}}</p>
                    <div *ngFor="item of redFlagsAdded">
                        <p >{{ item }}</p>
                    </div>
                   
                    <!-- <p>{{ }}</p> -->
                    <!-- hidden traits -->
                    <!-- your-match -->
                </div>
            </div>
        </div>
    </div>
</div>
