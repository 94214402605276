<div class="container-fluid"> 
    <div class="nav-box">
            <div class="row">
                <div class="col">
                        <div class="tag-line">
                            <p style="color:white">
                                Create your own personal customized guide for happiness and 
                                self-esteem.
                            </p>
                    </div>
               
                </div>

            <div class="row">
                <div class="lagos">
                    <div class="text">
                        <div class="description">
                            <p id="description">Explore your self-esteem 
                                and  ways to improve it, 
                                how you handle guilt 
                                and what to do about it, 
                                and even assess what you 
                                need and want in a 
                                long-term relationship.</p>
                 
                        </div>
                        <div class="download">
                            <a href="../../assets/workbook/lagos-toolkit_2022.pdf" download="lagos-toolkit_2022">
                                <span>Download <br>Lago's Workshop</span> 
                            </a>
                            <p id="free"> free of charge as part of Paul Lagomarcino's Legacy.</p>
                        </div>
                    </div>
             
                    <a href="workbook">
                        <div class="work-book">
                            <img src="../../assets/workbook/pages/1_lagos.jpg" alt="">
                        </div>
                    </a>
                </div>
             
           
      
            </div>
            <div class="row">
                <div class="col">
                    <div class="">
    
                  
                    </div>
                </div>
            </div>
            
                
            
            </div>
       
        

    </div>
</div>
