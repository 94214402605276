<div class="container-fluid">
    <div class="row">
        <div class="col">
            <p>
                Do you ever notice that insecure people end up with insecure people? 
                Insecurities, what I call “Red Flags,” start to cause major pain and 
                problems in relationships and those relationships often end in disaster.
            </p>
            <p>
                The sad fact is you cannot fix another person’s insecurities. 
                That has to come from within the individual, who must learn how to focus 
                on positive traits as opposed to negative traits. Everyone needs to work on 
                negative traits because they are the root of pain within us, 
                as well as in relationship with others.
            </p>
            <p>
                What I suggest to you – and put this in your “tool kit” – when you begin to 
                date a new person and feel there is a potential long-term relationship,
                look carefully at Lago’s Red Flags/Blue Flags.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <H6>Think About Your Potential Match</H6>
            <p>If you’re in a relationship now, list the good things about that person.</p>
            <hr>
            <hr>
            <hr>
            <p>Next, list any of your concerns about that person.</p>
            <hr>
            <hr>
            <hr>
            <button>Skip</button>
            <p>
                Now, every human being has Red Flags and Blue Flags. Some of the Red Flags -- for 
                instance, chemical abuse, jealousy, possessiveness, lying, unfaithfulness – are more 
                severe than moodiness or worrying what others think. The more severe the Red Flag, 
                the more damaging it will be to any relationship, regardless of how hard you try to overlook it.
            </p>
            <p>
                So, it is imperative to try to recognize early in the dating relationship Red Flags that 
                show themselves, as well as the Blue Flags which attracted you to that person.
            </p>
            <p>
                When Red Flags show themselves more frequently, you need to share your concern with 
                your potential loved one, and pay attention to how he or she reacts. The more defensive 
                that person is, for instance, denying that problem, the greater risk that the relationship 
                is destined for trouble.
            </p>
            <p>
                This doesn’t mean you have to stop dating, but moreover recognize that you will not 
                allow yourself to fall in love with that person until you see a committed effort to change 
                the Red Flag behavior. That person still might be a lot of fun to be with, but enjoy their 
                companionship on a more limited basis.
            </p>
            <p>
                The healthier response from your potential mate, if confronted with a Red Flag trait, 
                would be “I know I have that problem and am willing to work on it.” That would be a 
                good sign.
            </p>
            <button (click)="onNext('../your-flags')">Next</button>
        </div>
    </div>
</div>

