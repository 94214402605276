import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { OnInit } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit {
  constructor( private meta: Meta, private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'Download Lagos Workshop and create your own personal customized guide for happiness and self-esteem, your way. Explore your self-esteem and ways to improve it, how you handle guilt and what to do about it, and even assess what you need and want in a long-term relationship. Available online at no charge as part of Paul Lagomarcinos legacy.'},
      {name: 'author', content: 'kristinCassidy'},
      {name: 'keywords', content: 'Paul Lagomarcino, mental health, Lagos, self-esteem, relationships, red flag blue flag'}
    ]);
    this.setTitle('Home Page');
  }
  public setTitle( newTitle: string) {
    this.title.setTitle( newTitle );
  }
 ngOnInit() {

 }
}
 // title = 'pauls-tool-kit';
  // name = 'Lagos Tool Kit';