import { Component, OnInit } from '@angular/core';
import { NgForm,  FormGroup } from '@angular/forms';
import { Flag } from 'src/app/1_shared/models/flag.model';
import { FlagsService } from 'src/app/1_shared/services/flags.service';

@Component({
  selector: 'app-blue-flags',
  templateUrl: './blue-flags.component.html',
  styleUrls: ['./blue-flags.component.css']
})
export class BlueFlagsComponent implements OnInit {
  // blueFlagsForm: FormGroup
  blueFlagInventory: Flag[] = [];
  addedBlueFlags: Flag[] = [];

  blueFlags: string[] = [];
  redFlags: string[] = [];
  // selectedFlags: Flag[] = [];
  selectArray: string[] = [];

  constructor(private flagsService: FlagsService) { }

  ngOnInit() {
    this.blueFlagInventory = this.flagsService.blueFlagInventory;
    this.flagsService.addedBlueFlagsSub.subscribe(
      flags => {
        this.blueFlags = flags;
      }
    )
  }

  onAddFlag(form: NgForm) {
    const formValue = form.value;
    const newFlag = new Flag(null, formValue.flagLabel, true);
    this.addedBlueFlags.push(newFlag);

    this.flagsService.blueAddedSubject.next(this.addedBlueFlags);
    console.log(newFlag.label);
    console.log(this.addedBlueFlags);
    form.reset();
  }

  onNext(form: NgForm) {
    let checked: string[] = [];
    checked = this.selectArray;
    this.flagsService.blueSelectedSubject.next(checked);
    this.flagsService.blueAddedSubject.next(this.addedBlueFlags);

  }

  onChecked (id: string) {
    if( !this.selectArray.includes(id)) {
      this.selectArray.push(id);
      console.log(this.selectArray)
    } else if( this.selectArray.includes(id)) {
      let i = this.selectArray.indexOf(id);
      let newSelectArray: string[] = [];
      this.selectArray.splice(i, 1);
      newSelectArray = this.selectArray.slice();
      this.selectArray = newSelectArray;
     console.log(this.selectArray);
    }  
    
  }

}