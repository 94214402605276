import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { FlagsFormResults } from '../1_shared/models/flags-form-results.model';
import { FlagsService } from '../1_shared/services/flags.service';


@Component({
  selector: 'app-relationships',
  templateUrl: './relationships.component.html',
  styleUrls: ['./relationships.component.css'],
  host: { "class" : "router-outlet" }
})
export class RelationshipsComponent implements OnInit {
  submitted: boolean = false;
  results: FlagsFormResults;

  constructor(private meta: Meta, private title: Title,
              private flagsService: FlagsService) { 
    this.meta.addTags([
      {name: 'description', content: 'Download Lagos Workshop and create your own personal customized guide for happiness and self-esteem, your way. Explore your self-esteem and ways to improve it, how you handle guilt and what to do about it, and even assess what you need and want in a long-term relationship. Available online at no charge as part of Paul Lagomarcinos legacy.'},
      {name: 'author', content: 'kristinCassidy'},
      {name: 'keywords', content: 'Paul Lagomarcino, mental health, Lagos, self-esteem, relationships, red flag blue flag, self help'}
    ]);
    this.setTitle('Relationships');
  }
  public setTitle( newTitle: string) {
    this.title.setTitle( newTitle );
  }
  ngOnInit() {
   
    this.flagsService.submittedSubject.subscribe(
      results => {
        this.results = results;
        console.log(results);
        this.submitted = true;
      }
    )

  }

}
