<div class="container-fluid">
    <div class="row">
        <div class="page-header">
            <h4 class="page-header-text">How to find the Right Match</h4>
            <hr>
            <p class="subheader-text">Tools to ensure your love relationships are healthy and life-long.</p>
        </div>
       
    </div>
    <div class="row">
        <nav class="navbar">
            <div class="btn-group">
                <a routerLink="flags-intro">  
                    <button type="button" class="btn btn-sm btn-outline-dark"> Red Flags/ Blue Flags</button>
                </a>
                <a href="#">  
                    <button type="button" class="btn btn-sm btn-outline-dark"> Download Worksheet</button>
                </a>
            </div>
        </nav>
    </div>

    <div class="row" id="soon">
        <span> *Coming Soon* </span>
    </div>
   
    <div>
        <div class="row header">
            <div class="col">
                <h5 class="header-text"> Red Flags / Blue Flags <i class="bi bi-flag-fill"></i></h5>
            </div>
        </div>
        <div>
            <router-outlet></router-outlet>
            <!-- <app-flags-intro></app-flags-intro>
            <app-hidden-traits></app-hidden-traits>
            <app-your-match></app-your-match> -->
        </div>
        <!-- <app-flags-form></app-flags-form>
        <div *ngIf="submitted">
            <app-results></app-results>
        </div> -->
    </div>
</div>
