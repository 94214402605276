import { Component, OnInit, ViewChild} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-workbook',
  templateUrl: './workbook.component.html',
  styleUrls: ['./workbook.component.css'],
  host: { "class" : "router-outlet" }
})
export class WorkbookComponent implements OnInit {
  // @ViewChild('') WorkbookPages: Component;
  menuOpen: boolean = false;
  showDownload: boolean = true;


  constructor(private meta: Meta, 
              private title: Title,
              private router: Router,
              private route: ActivatedRoute) { 
    this.meta.addTags([
      {name: 'description', content: 'Download Lagos Workshop and create your own personal customized guide for happiness and self-esteem, your way. Explore your self-esteem and ways to improve it, how you handle guilt and what to do about it, and even assess what you need and want in a long-term relationship. Available online at no charge as part of Paul Lagomarcinos legacy.'},
      {name: 'author', content: 'kristinCassidy'},
      {name: 'keywords', content: 'Paul Lagomarcino, mental health, Lagos, self-esteem, relationships, red flag blue flag, self help'}
    ]);
    this.setTitle('Workbook');
  }
  public setTitle( newTitle: string) {
    this.title.setTitle( newTitle );
  }
  ngOnInit() {


  }

  onNavigate() {
    this.router.navigate([`pages#intro`], {relativeTo: this.route});
  }

  // scroll(el: HTMLElement) {
  //   el.scrollIntoView({behavior: 'smooth'});
  // }

}



