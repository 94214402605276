import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-paul',
  templateUrl: './paul.component.html',
  styleUrls: ['./paul.component.css'],
  host: {'class': 'router-outlet'}
})
export class PaulComponent implements OnInit {
  bio1 = `Paul A. Lagomarcino, BA, MA, ACSW, of Alton, Illinois, was a well-respected psychotherapist for forty years. 
          Two of his most treasured and unique gifts were providing “tools” to his patients that guide them through 
          developing and maintaining their own self-esteem, tools they can refer to and reuse throughout their lives, 
          and teaching a system of “Red Flags/Blue Flags” based on analyzing traits, activities and interests to help 
          clients choose the right life long choose the right life-long partner.`;
  bio2 = `Paul was raised in Tampa, FL, where he attended Jesuit High School. He earned his B.A. in Psychology and M.A. 
          in Psychiatric Social Work from St. Louis University, and was accredited by the Academy of Certified Social Workers. 
          Early in his career, Paul directed the Alton State Hospital Alcoholic Treatment Center and was a supervisor at the 
          Madison County Mental Health Center. A true visionary in the mental health field, he founded the first Day Treatment 
          Center in Alton, an intensive outpatient psychiatric program for the Metro East area. He also developed the first 
          Police Mental Health Program/Employee Assistance Programs (EAP) in the Metro East area which helped police and their 
          families manage the emotional stress related to the nature of their work.`;
  bio3 = `Paul retired after four decades of private practice and then volunteered with 
          St. Anthony's Hospice in Alton for the next nine years. Truly a shining example 
          of an unselfish person who always wanted to help others, Paul guided his patients 
          through personal trauma, crisis and indecision using his easily understood and replicable techniques. 
          Paul’s counseling sessions left his patients with a workable, tangible 
         “Toolbox” of guides and exercises to keep them on track throughout their lives.`;
  bio4 = `Married to his wife, Suzanne, for fifty-seven years, Paul had three children: Debbie (Brad),  San Luis Obispo, CA, 
          Brian (Candy), Jonesboro, AR, and Natalie (Matt), Corte Madera, CA, and six grandchildren.  Fully balancing his life outside his practice, 
          Paul and Suzanne traveled extensively. Paul proudly earned his pilot's license despite getting lost on his first solo flight. 
          A sports and movie enthusiast, he loved watching television, and could explain in unending detail why it was good for his health.`;
  bio5 = `LAGO’S TOOLBOX   Your Personal Workbook for Building Self-Esteem 
          & Choosing a Lifetime Mate is a collection of the tools Paul developed and provided to his clients throughout his 
          professional career. His wife, Suzanne, was determined to capture those tools so that others can continue to learn 
          and benefit from Paul’s style of counseling.  Paul named the book himself, on his deathbed, a testament to his humble 
          demeanor and gentle outlook on the science of psychotherapy`;



  constructor( private meta: Meta, private title: Title) { 
        this.meta.addTags([
                {name: 'description', content: 'Download Lagos Workshop and create your own personal customized guide for happiness and self-esteem, your way. Explore your self-esteem and ways to improve it, how you handle guilt and what to do about it, and even assess what you need and want in a long-term relationship. Available online at no charge as part of Paul Lagomarcinos legacy.'},
                {name: 'author', content: 'kristinCassidy'},
                {name: 'keywords', content: 'Paul Lagomarcino, mental health, Lagos, self-esteem, relationships, red flag blue flag, self help'}
              ]);
              this.setTitle("Paul's Legacy");
            }
            public setTitle( newTitle: string) {
              this.title.setTitle( newTitle );
            }
            ngOnInit() {
          
          
            }

}
