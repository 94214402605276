import { Injectable } from "@angular/core";
// import { NgForm } from "@angular/forms";
import { Subject } from "rxjs";
import { Flag } from "../models/flag.model";
import { FlagsFormResults } from "../models/flags-form-results.model";

@Injectable({ providedIn: 'root'})
export class FlagsService {
    blueFlagOptions: Flag[] = [
        new Flag('b1', 'Is Honest'),
        new Flag('b2', 'Has Sense of Humor'),
        new Flag('b3', 'Talks positive about self and others'),
        new Flag('b4', 'Is assertive'),
        new Flag('b5', 'Usually in a good and "up" mood'),
        new Flag('b6', 'Is Successful at work'),
        new Flag('b7', 'Trusts others'),
        new Flag('b8', 'Expresses anger constructively'),
        new Flag('b9', 'Compliments and "strokes" others'),  
        new Flag('b10', 'Looks for positive traits in others'),
        new Flag('b11', 'Admits when wrong and apologizes'),
        new Flag('b12', 'Likes to try new things -- adventurous'),
        new Flag('b13', 'Ambitious'),
        new Flag('b14', "Sensitive to lover's needs"),
        new Flag('b15', 'Receptive to different opinions'),
        new Flag('b16', 'Is tender and shares inner feelings'),
        new Flag('b17', 'Has close friendships'),
        new Flag('b18', 'Has balanced life style'),
        new Flag('b19', 'Enjoys regular physical outlets'),
        new Flag('b20', 'Likes to relax and have fun'),
        new Flag('b21', 'Unselfish'),
        new Flag('b22', 'Thoughtful and considerate'),
        new Flag('b23', 'Non-judgmental of others')
    ];
    redFlagOptions: Flag[] = [
        new Flag('r1', 'Abuses of alcohol or drugs'),
        new Flag('r2', 'Regularly depressed'),
        new Flag('r3', 'Exhibits explosive temper'),
        new Flag('r4', 'Keeps anger bottled up inside'),
        new Flag('r5', 'Domineering and controlling'),
        new Flag('r6', 'Promiscuous'),
        new Flag('r7', 'Lies regularly'),
        new Flag('r8', 'Has cheated in relationships'),
        new Flag('r9', 'Worries excessively'),
        new Flag('r10', 'Is a workaholic'),
        new Flag('r11', 'Has unstable work record'),
        new Flag('r12', 'Jealous'),
        new Flag('r13', 'Puts self down frequently'),
        new Flag('r14', 'Over-critical of others'),
        new Flag('r15', 'Often sees negative traits in others'),
        new Flag('r16', 'Mistrusts others'),
        new Flag('r17', 'Fears of being alone'),
        new Flag('r18', 'Extremely moody'),
        new Flag('r19', 'Mainly interested in money and sex'),
        new Flag('r20', 'Stubborn - their way or no way'),
        new Flag('r21', 'Unable to share tender feelings'),
        new Flag('r22', 'Inconsiderate'),
        new Flag('r23', 'Lacks empathy'),
        new Flag('r24', 'Brags regularly'),
        new Flag('r25', 'Has no close friendships'),
        new Flag('r26', 'Judges others frequently'),
        new Flag('r27', 'Tends to be loud or obnoxious'),
        new Flag('r28', 'Self-centered in the bedroom'),
        new Flag('r29', 'Obsessed with body image'),
        new Flag('r30', 'Avoids quality time together'),
        new Flag('r31', 'Sloppiness'),
        new Flag('r32', 'Lacks cleanliness')
    ];

    blueFlagInventory: Flag[] = [
        new Flag('b1', 'Is Honest'),
        new Flag('b2', 'Has Sense of Humor'),
        new Flag('b3', 'Talks positive about self'),
        new Flag('b4', 'Talks positive about others'),
        new Flag('b5', 'Is assertive'),
        new Flag('b6', 'Generally in a good mood'),
        new Flag('b7', 'Is successful at work'),
        new Flag('b8', 'Encourages seprate activities'),
        new Flag('b9', 'Encourages seprate friendships'),
        new Flag('b10', 'Generally trusts others'),
        new Flag('b11', 'Expresses anger constructively'),
        new Flag('b12', 'Compliments others without "hook'),  
        new Flag('b13', 'Looks for positive traits in others'),
        new Flag('b14', 'Admits when wrong and apologizes'),
        new Flag('b15', 'Tries new things -- adventurous'),
        new Flag('b16', 'Ambitious'),
        new Flag('b17', 'Sensitive outside the bedroom'),
        new Flag('b18', "Sensitive to lover's needs"),
        new Flag('b19', 'Receptive to others opinions'),
        new Flag('b20', 'Is tender and shares inner feelings'),
        new Flag('b21', 'Has close friendships'),
        new Flag('b22', 'Is non-judgmental'),
        new Flag('b23', 'Has balanced life style'),
        new Flag('b24', 'Enjoys regular physical outlets'),
        new Flag('b25', 'Confident in physical appearance'),
        new Flag('b26', 'Unselfish'),
        new Flag('b27', 'Thoughtful and considerate'),
        
    ];
    redFlagInventory: Flag[] = [
        new Flag('r1', 'Abuses of alcohol or drugs'),
        new Flag('r2', 'Regularly depressed'),
        new Flag('r2', 'Physically abusive'),
        new Flag('r2', 'Mentally abusive'),
        new Flag('r3', 'Exhibits explosive temper'),
        new Flag('r4', 'Keeps anger bottled up inside'),
        new Flag('r5', 'Domineering and controlling'),
        new Flag('r6', 'Promiscuous'),
        new Flag('r7', 'Lies regularly'),
        new Flag('r8', 'Has cheated in relationships'),
        new Flag('r9', 'Worries excessively'),
        new Flag('r10', 'Is a workaholic'),
        new Flag('r11', 'Has unstable work record'),
        new Flag('r12', 'Jealous'),
        new Flag('r13', 'Puts self down frequently'),
        new Flag('r14', 'Over-critical of others'),
        new Flag('r15', 'Often sees negative traits in others'),
        new Flag('r16', 'Mistrusts others'),
        new Flag('r17', 'Fears of being alone'),
        new Flag('r18', 'Extremely moody'),
        new Flag('r19', 'Mainly interested in money and sex'),
        new Flag('r20', 'Stubborn - their way or no way'),
        new Flag('r21', 'Unable to share tender feelings'),
        new Flag('r22', 'Inconsiderate'),
        new Flag('r23', 'Lacks empathy'),
        new Flag('r24', 'Brags regularly'),
        new Flag('r25', 'Has no close friendships'),
        new Flag('r26', 'Judges others frequently'),
        new Flag('r27', 'Tends to be loud or obnoxious'),
        new Flag('r28', 'Self-centered in the bedroom'),
        new Flag('r29', 'Obsessed with body image'),
        new Flag('r30', 'Avoids quality time together'),
        new Flag('r31', 'Sloppiness'),
        new Flag('r32', 'Lacks cleanliness')
    ];
    selectBlueFlags: string[] = [];

    addedBlue: string[] = [];
    addedRed: string[] = [];

    addedBlueFlags: Flag[] = [];
    selectRedFlags: string[] = [];  
    addedRedFlags: Flag[] = [];

    blueSelectedSubject = new Subject<string[]>();
    blueAddedSubject = new Subject<Flag[]>();

    newBlueLabelSubject = new Subject<string>();
    newRedLabelSubject = new Subject<string>();

    addedBlueFlagsSub = new Subject<string[]>();
    addedRedFlagsSub = new Subject<string[]>();

    redSelectedSubject = new Subject<string[]>();
    redAddedSubject = new Subject<Flag[]>();
    submittedSubject = new Subject<FlagsFormResults>();
    results: FlagsFormResults;
    // compileResultsSubject:;


    constructor() {}

    createFlagResults(selectedBlueFlags: string[], addBlueFlags: Flag[], selectedRedFlags: string[], addRedFlags: Flag[]) {
        this.selectBlueFlags = selectedBlueFlags;
        this.addedBlueFlags = addBlueFlags;
        this.selectRedFlags = selectedRedFlags;
        this.addedRedFlags = addRedFlags;
        this.results = new FlagsFormResults( null, null, this.selectBlueFlags,this.addedBlueFlags,this.selectRedFlags, this.addedRedFlags);
        this.submittedSubject.next(this.results);
        // console.log(results);
    }

    getResults() {
        return this.results;
    }

    // getAddedBlueFlags() {
        // this.newBlueLabelSubject.subscribe(
            // label => {

                // const newFlag = new Flag( null, label);
                // this.addedBlueFlags.push(newFlag);
                // console.log(this.addedBlueFlags.slice())
                // this.blueAddedSubject.next(this.addedBlueFlags.slice());
            // }
       // )

    //}

    putBlueFlag(label: string) {
        this.addedBlue.push(label);
        console.log(this.addedBlue.slice());
        let blueFlags: string[] = this.addedBlue.slice();
        this.addedBlueFlagsSub.next(blueFlags);
        this.addedBlue = blueFlags;
    }

    deleteBlueFlag(label) {
       let i = this.addedBlue.indexOf(label);
       let updatedBlue: string[] = [];
       this.addedBlue.splice(i, 1);
       updatedBlue = this.addedBlue.slice();
       this.addedBlue = updatedBlue;
       this.addedBlueFlagsSub.next(this.addedBlue);
       console.log(this.addedBlue);
    }

    putRedFlag(label: string) {
        this.addedRed.push(label);
        console.log(this.addedRed.slice());
        let redFlags: string[] = this.addedRed.slice();
        this.addedRedFlagsSub.next(redFlags);
        this.addedRed = redFlags;
    }

    deleteRedFlag(label) {
       let i = this.addedRed.indexOf(label);
       let updatedRed: string[] = [];
       this.addedRed.splice(i, 1);
       updatedRed = this.addedRed.slice();
       this.addedRed = updatedRed;
       this.addedRedFlagsSub.next(this.addedRed);
       console.log(this.addedRed);
    }
}
