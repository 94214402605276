import { Component, OnInit } from '@angular/core';
import { Flag } from 'src/app/1_shared/models/flag.model';
import { FlagsFormResults } from 'src/app/1_shared/models/flags-form-results.model';
import { FlagsService } from 'src/app/1_shared/services/flags.service';


@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css']
})
export class ResultsComponent implements OnInit {
  entries: FlagsFormResults;
  name: string;
  blueFlagsSelected: string[];
  blueFlagsAdded: Flag[];
  redFlagsSelected: string[];
  redFlagsAdded: Flag[];
  results: FlagsFormResults;

  constructor(private flagsService: FlagsService) { }

  ngOnInit(): void {
    this.results = this.flagsService.getResults();
    this.flagsService.submittedSubject.subscribe(
      results => {
        this.entries = results;
        this.name = results.userName;
        this.blueFlagsSelected = results.blueFlags;
        this.blueFlagsAdded = results.addedBlueFlags;
        this.redFlagsSelected = results.redFlags;
        this.redFlagsAdded = results.addedRedFlags;
        
      }
    )
  }

}
