<div class="container-fluid">
    <form (ngSubmit)="onNext(red)" #red="ngForm">
        <div class="row">
            <div class="col">
                <div *ngFor="let flag of redFlagInventory; let i = index">
                    <div class="d-flex justify-content-between">
                        <label class="l-case">{{ flag.label }}</label>
                        <input 
                            (click)="onChecked(flag.id)"
                            name=" {{ flag.id }}"
                            type="checkbox"
                            id="i"
                            ngModel> 
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <p class="l-case">Additional Red Flags Important to Me:</p>
            <div *ngFor="let flag of redFlags; let i = index">
                <div class="d-flex justify-content-between">
                    <label class="l-case">{{ flag.label }} </label>
                    <input type="checkbox" class="checkbox" checked>
                </div>
                <!-- <hr> -->
            </div>
        </div>
        <div *ngFor="let flag of redFlags; let i = index">
            <div class="flags">
                <ul>
                    <li>{{ flag }}
                        <a (click)="onDeleteRedFlag(flag)">
                            <i *ngIf="showRedInput" class="bi bi-x-circle"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div>
            <form (ngSubmit)="onAddFlag(moreRed)" #moreRed="ngForm">
                <input 
                    type="text"
                    id="flag"
                    name="flagLabel"
                    ngModel
                    required>
                <button 
                    type="submit"
                    class="btn btn-sm btn-outline-primary l-case"
                    id="addBtn">  
                    <i class="bi bi-plus-circle"></i>
                    Add Red Flag
                </button>
            </form>
        </div>

        <button 
            type="submit"
            class="btn btn-sm btn-outline-primary l-case">  
            Next
        </button>
    </form> 
</div>
