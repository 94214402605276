
<div class="navbar" id="footerBottom">
    <div class="container-fluid">
        <div class="row footer-box">
            <div class="col">
                <p>Lago's Tool Kit Workshop is available at no charge  as part of Paul Lagomarcino's Legacy.</p>
            </div>
            
        </div>
    
    </div>
</div>