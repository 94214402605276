<div class="header">
    <app-header></app-header>
</div>   
<div>
    <app-navigation></app-navigation>
</div>
<!-- <div class="body"> -->
    <!-- <div>
        <app-side-bar></app-side-bar>
    </div> -->
    <div class="router-outlet">
        <router-outlet></router-outlet>
    </div>
<!-- </div> -->

<div class="footer">
    <app-footer></app-footer>
</div>

