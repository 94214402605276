import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SplashComponent } from './splash/splash.component';
import { AnnouncementComponent } from './announcement/announcement.component';
import { PaulComponent } from './paul/paul.component';
import { RelationshipsComponent } from './relationships/relationships.component';
import { WorkbookComponent } from './workbook/workbook.component';
import { FlagsFormComponent } from './relationships/flags-form/flags-form.component';
import { HomeComponent } from './home/home.component';
import { LagosWorkbookComponent } from './workbook/lagos-workbook/lagos-workbook.component';
import { DownloadComponent } from './download/download.component';
import { FiveFingersComponent } from './download/five-fingers/five-fingers.component';
import { FlagsIntroComponent } from './relationships/1_flags-intro/flags-intro.component';
import { HiddenTraitsComponent } from './relationships/2_hidden-traits/hidden-traits.component';
import { YourMatchComponent } from './relationships/3_your-match/your-match.component';
import { ResultsComponent } from './relationships/results/results.component';
import { YourFlagsComponent } from './relationships/4_your-flags/your-flags.component';
import { PartnersFlagsComponent } from './relationships/5_partners-flags/partners-flags.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch:'full' },
  { path: 'home', component: HomeComponent },
  { path: 'coming-soon', component: AnnouncementComponent },
  { path: 'download', component: DownloadComponent},
  { path: 'five-fingers', component: FiveFingersComponent},
        // { path: 'flags', component: }
  
  { path: 'workbook', component: WorkbookComponent,
      children: [ 
        // { path: '', component: LagosWorkbookComponent },
        { path: 'pages', component: LagosWorkbookComponent },
      ]  },
  { path: 'paul-lago', component: PaulComponent },
  { path: 'relationships', component: RelationshipsComponent,
      children: [
        // { path: '', redirectTo: '/flags-intro', pathMatch: 'full'},
        { path: 'flags-intro', component: FlagsIntroComponent},
        // { path: 'flags-form', component: FlagsFormComponent },
        { path: 'hidden-traits', component: HiddenTraitsComponent},
        { path: 'your-match', component: YourMatchComponent},
        { path: 'your-flags', component: YourFlagsComponent},
        { path: 'partners-flags', component: PartnersFlagsComponent},
        { path: 'results', component: ResultsComponent}

    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
