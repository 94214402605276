import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lagos-workbook',
  templateUrl: './lagos-workbook.component.html',
  styleUrls: ['./lagos-workbook.component.css'],
  // host: { "class" : "window" }
})
export class LagosWorkbookComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
