<div class="container-fluid">
    <div class="page">
        <div class="row paul-row">
            <!-- <div class="col-1 spacer"></div> -->
            <div class="col portrait-col">
                <img 
                    class="portrait" 
                    src="../../assets/graphics/paul_portrait_edit@0.5x.jpg" 
                    alt="portrait of Paul Lagomarcino">
            </div>
            <div class="col title-col">
                <div>
                    <h1 class="title">Paul<br>Lagomarcino</h1> 
                </div> 
            </div>
            <!-- <div class="col-5 spacer"></div> -->
            
        </div>
        <div class="row quote-row">
            <div class="col spacer"></div>
            <div class="col spacer"></div> 
            <div class="col quote-col">
                <p class="quote">
                    <span>"I love you and I am </span><wbr>
                    <span>so proud of you."</span>
                </p>
                <p class="name">--Paul Lagomarcino</p>
            </div>
            
        </div>
    
        <div class="row justify-content-center">
            <div class="col text-col">
                <p class="bio-text"> {{ bio1 }} </p>
                <p class="bio-text"> {{ bio2 }} </p>
                <p class="bio-text"> {{ bio3 }} </p>
                <p class="bio-text"> {{ bio4 }} </p>
                <p class="bio-text"> {{ bio5 }} </p>
            </div>
        </div>
        <div class="row">
            <div class="btn-group">
                <!-- <a href="workbook">
                    <button class="btn btn-light">
                        <span>workbook</span>
                    </button>   
                </a> -->
                <a href="https://www.legacy.com/us/obituaries/thetelegraph/name/paul-lagomarcino-obituary?id=31574926">
                    <button  type="button" class="btn btn-light" >
                        <span>Obituary</span>
                    </button> 
                </a>
            </div>
        </div>

    </div>
</div>
