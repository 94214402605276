
<div class="container-fluid">
    <div class="row">
        <nav class="navbar">
            <div class="button-group">
                <a onclick="history.back()">
                    <button class="button">
                        <i class="bi bi-chevron-left"></i>
                        <label for="">back</label>
                       
                    </button>
                </a>
            </div>
         
        </nav>
    </div>
    <div class="row">
        <p>pdf download options</p>
    </div>
    <div class="row">
        <div class="col">

            <a href="../../assets/workbook/lagos-toolkit_2022.pdf" downlod="lagos-toolkit_2022">
                <div class="option">
                    <img src="../../assets/graphics/toolkit_transparency.png" alt="">
                    <p>Full Workbook</p>
                </div>
            </a>
        </div>
    </div>
    <div class="row part1">
        <p>Part 1</p>
        <!-- <div class="col">
            <a routerLink="/five-fingers">
                <div class="option">
                    <img src="../../assets/workbook/worksheets/five-fingers/2_Hand.jpg" alt="">
                    <p>Five Fingers</p>
                </div>
            </a>
        </div> -->
        <div class="col">
            <a  href="../../assets/workbook/worksheets/1_five-fingers/five-fingers.pdf" 
                download="lagos_five-fingers">
                <div class="option">
                    <img src="../../assets/workbook/worksheets/1_five-fingers/2_Hand.jpg" alt="">
                    <p>1. Five Fingers</p>
                </div>
            </a>
        </div>
        <div class="col">
            <a href="../../assets/workbook/worksheets/2_slippery-slope/slippery-slope.pdf" 
            download="lagos_slippery-slope">
                <div class="option">
                    <img src="../../assets/workbook/worksheets/2_slippery-slope/slippery-slope.jpg" alt="">
                    <p>2. Slippery Slope</p>
                </div>
            </a>
        </div>
        <div class="col">
            <a href="../../assets/workbook/worksheets/3_negative-thoughts/negative-thoughts.pdf" 
            download="lagos_negative-thoughts">
                <div class="option">
                    <img src="../../assets/workbook/worksheets/3_negative-thoughts/negative-thoughts.jpg" alt="">
                    <p>3. Controlling Negative Thoughts</p>
                </div>
            </a>
        </div>
        <div class="col">
            <a href="../../assets/workbook/worksheets/4_others/mistakes.pdf" 
            download="lagos_mistakes">
                <div class="option">
                    <img src="../../assets/workbook/worksheets/4_others/mistakes.jpg" alt="">
                    <p>4. Mistakes</p>
                </div>
            </a>
        </div>
        <div class="col">
            <a href="../../assets/workbook/worksheets/5_guilt/handling-guilt.pdf" 
            download="lagos_handling-guilt">
                <div class="option">
                    <img src="../../assets/workbook/worksheets/5_guilt/handling-guilt.jpg" alt="">
                    <p>5. Handling Guilt</p>
                </div>
            </a>
        </div>
        <div class="col">
            <a href="../../assets/workbook/worksheets6_assertive" 
            download="lagos_slippery-slope">
                <div class="option">
                    <img src="../../assets/workbook/worksheets/6_assertive" alt="">
                    <p>6. Being Assertive</p>
                </div>
            </a>
        </div>
        <div class="col">
            <a href="../../assets/workbook/worksheets/7_myself" 
            download="lagos_slippery-slope">
                <div class="option">
                    <img src="../../assets/workbook/worksheets/7_myself" alt="">
                    <p>7. Evaluating Myself</p>
                </div>
            </a>
        </div>

        <div class="col">
            <a href="../../assets/workbook/worksheets/8_master-list" 
            download="lagos_slippery-slope">
                <div class="option">
                    <img src="../../assets/workbook/worksheets/8_master-list" alt="">
                    <p>8. Master List</p>
                </div>
            </a>
        </div>
        <div class="col">
            <a href="../../assets/workbook/worksheets/master-list" 
            download="lagos_slippery-slope">
                <div class="option">
                    <img src="../../assets/workbook/worksheets/master-list" alt="">
                    <p>9. Positive Qualities</p>
                </div>
            </a>
        </div>
        <div class="col">
            <a href="../../assets/workbook/worksheets/master-list" 
            download="lagos_slippery-slope">
                <div class="option">
                    <img src="../../assets/workbook/worksheets/master-list" alt="">
                    <p>10. Daily Deeds</p>
                </div>
            </a>
        </div>
    </div>
    <div class="row">
        <p>Part 2</p>
        <div class="col">
            <div class="option">
                <img src="../../assets/workbook/worksheets/11_flags 1.jpg"  alt="">
                <p>Flags worksheets</p>
            </div>
        </div>
    </div>
</div>