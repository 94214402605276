<div class="container-fluid">
    <div class="row">
        <div class="col">
            <p class="intro-text">
                Have you ever hidden some of your traits that might turn your date off? Record those traits here.
            </p>
            <form (ngSubmit)="onAddTurnOff(trait)" #trait="ngForm">
                <!-- hidden traits input form -->
                <input                            
                    type="text"
                    name="turnOff"
                    id="i"
                    ngModel
                    required>
                <button type="submit">Add</button>
            </form>
       
            <!-- <hr class="trait-line"> -->
            <div *ngFor="let trait of turnOffs; let i = index">
                <a (click)="onEditTurnOff(trait)">
                    <span class="traits">{{ trait }}</span>
                </a>
                
                <hr class="trait-line">
            </div>
            <hr *ngIf="turnOffs.length < 1">
            <hr *ngIf="turnOffs.length < 2">
            <hr *ngIf="turnOffs.length < 3">
            <hr *ngIf="turnOffs.length < 4"> 
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="traits-header">
                <h4>Traits I usually Hide About Myself</h4> 
            </div>
        
            
            <p class="intro-text">
                List traits about yourself that you tend to hide during the first several months of a relationship. Remember, 
                these don’t have to be only red flags. They can be blue flags and/or red flags! Many people hide some of their 
                blue flag traits too, being afraid to appear too smart for instance, which is not being honest. 
                What traits might you be hiding?
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h6>Good Traits</h6>
            <form (ngSubmit)="onAddGoodTrait(good)" #good="ngForm">
                <input                            
                    type="text"
                    name="goodTrait"
                    id="i"
                    ngModel
                    required>
                <button type="submit">Add</button>

            </form>
            
            <div *ngFor="let trait of goodTraits; let i = index">
                <span class="traits">{{ trait }}</span>
                <hr class="trait-line">
            </div>
            <hr *ngIf="goodTraits.length < 1">
            <hr *ngIf="goodTraits.length < 2">
            <hr *ngIf="goodTraits.length < 3">
        </div>
        <div class="col">
            <h6>Bad Traits</h6>
            <form (ngSubmit)="onAddBadTrait(bad)" #bad="ngForm">
                <input                            
                    type="text"
                    name="badTrait"
                    id="i"
                    ngModel
                    required>
                <button type="submit">Add</button>
            </form>
            <div *ngFor="let trait of badTraits; let i = index">
                <span class="traits">{{ trait }}</span>
                <hr class="trait-line">
            </div>
            <hr *ngIf="badTraits.length < 1">
            <hr *ngIf="badTraits.length < 2">
            <hr *ngIf="badTraits.length < 3">
        </div>
        <div id="next">
            <div>
                <button (click)="onNext('../your-match') ">Next</button>
            </div>
        </div>
    </div>

</div>
