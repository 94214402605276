import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-hidden-traits',
  templateUrl: './hidden-traits.component.html',
  styleUrls: ['./hidden-traits.component.css']
})
export class HiddenTraitsComponent implements OnInit {
  turnOffs: string[] = [];
  goodTraits: string[] = [];
  badTraits: string[] = [];
  // showInput: boolean = true;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  onNext(path: string ) {
    this.router.navigate([path], {relativeTo: this.route})
  }

  onAddTurnOff(form: NgForm) {
    let trait = form.value.turnOff;
    this.turnOffs.push(trait);
    console.log(this.turnOffs);
    form.reset();
  }

  onDeleteTrait(trait: string) {

  }
  onAddGoodTrait(form: NgForm){
    let trait = form.value.goodTrait;
    this.goodTraits.push(trait);
    console.log(this.goodTraits);
    form.reset();
  }

  onAddBadTrait(form: NgForm) {
    let trait = form.value.badTrait;
    this.badTraits.push(trait);
    console.log(this.badTraits);
    form.reset();
  }

  // onSubmit(form: NgForm) {

  // }
  onEditTurnOff(trait: string) {

  }



}
