
  
<div class="container-fluid">
    <div class="row">
        <img class="tool-kit-icon" src="../../assets/graphics/toolkit_transparency.png" alt="tool kit icon">
    </div>

    <div class="row">
        <div class="col dark-red"> Dark Red</div>
        <div class="col med-red"> Med Red</div>
        <div class="col light-red"> Light Red</div>
        <!-- <div class="col dark-red"> Dark Red</div> -->
        <div class="col blue">Blue</div>
        <div class="col gray">Gray</div>
        <div class=" shade-8 col"> </div>
        <div class=" shade-9 col"> </div>
        <div class=" shade-10 col"> </div>
        <div class=" shade-11 col"> </div>
        <div class=" shade-12 col"> </div>
        <div class=" shade-7 col"> </div>
    </div>

    <div class="row">
        <div class="col shade-1">
            <!-- <p>light teal</p> -->
        </div>
        <div class="col shade-2">
            <!-- <p>warm tea</p> -->
        </div>
        <div class="col shade-3">
            <!-- <p>light gray</p> -->
        </div>
       
        <div class="col shade-5">
            <!-- <p>dark gray</p> -->
        </div>
      
        <div class="col shade-7">
            <p></p>
        </div>
        <div class="col shade-8">
            <p></p>
        </div>
        <div class="col shade-9">
            <p></p>
        </div>
        <div class="col shade-10">
            <p></p>
        </div>
        <div class="col shade-11">
            <p></p>
        </div>
        <div class="col shade-12">
            <p></p>
        </div>
       

    </div>


</div>