 <div class="row">
<!-- navbar -->
    <nav id="wb-header-nav" class="navbar">
        <div class="container-fluid">
            <div class="navbar-left">
                <div *ngIf="!menuOpen">
                         <!-- <div *ngIf="!menuOpen"
                         class="navbar-toggle" 
                         data-toggle="collapse"
                          data-target="#toc"> -->
                        <div>
                            <a (click)="menuOpen = true" >
                                <i class="bi bi-list"></i>
                            </a> 
                        </div>
                   
                </div>

 <!-- Contents  Header-->
                <div *ngIf="menuOpen" class="navbar"  id="contentsNav">
                    <h5 class="navbar-header">Contents</h5>
                    <div class="navbar-right">
                        <a (click)="menuOpen = false"><i class="bi bi-x"></i></a>
                    </div>
                </div>
            </div>
        <!-- <div appToggleclass="collapse navbar-collapse"> -->
            <div  class="navbar-right">   
                <a 
                    href="../../assets/workbook/lagos-toolkit_2022.pdf" 
                    download="lagos_tool_kit_2022">
                        <i class="bi bi-download"></i>
                </a>
            </div>
        </div>
    <!-- </div> -->
    </nav>
    
 <!-- Table of ContentsList Menu -->
        <div *ngIf="menuOpen" class="col-xs-4 contents" id="toc">
            <nav>
                <div>
                    <div class="part">
                        <div  class="btn-group btn-group-vertical">
                        <!-- <a href="#">
                            <button type="button"><p>Lago's Tool Kit</p></button>
                        </a> -->
                        <!-- <a href="workbook/pages/#intro"> -->
                            <button type="button" (click)="onNavigate()">
                            <!-- <button type="button"> -->
                                <p>-  Introduction</p></button>
                        <!-- </a> -->
                    </div>
                </div>
<!-- part 1 -->
                <div class="part">
                    <div class="navbar">
                       
                        <div class="container-fluid">
                            
                            <div class="navbar-left">
                                <a routerLink="workbook/pages/#part1"><h5>Part 1:</h5></a>
                            </div>
                            <div class="navbar-right">
                                
                                <a href="workbook/pages/#part1"><p class="title">Me Myself and I</p></a>
                            </div>
                        
                        </div>
                      
                    </div>

                    <div  class="btn-group btn-group-vertical">
                        <a href="workbook/pages/#chap1">
                        <!-- <a href="workbook/pages/#chap1"> -->
                            <button type="button">
                                <!-- <h6>1:</h6>  -->
                                <p>1. The Five Fingers of Self-Esteem</p>
                            </button>
                        </a>
                        <a href="workbook/pages/#chap2">
                            <button>
                                <!-- <h6>2:</h6> -->
                                <p>2. The Slippery Slope</p>
                            </button>
                        </a>
                        <a href="workbook/pages/#chap3">
                            <button>
                                <!-- <h6>chapter 3:</h6>  -->
                                <p>3. Controlling My Negative Thoughts</p>
                            </button>
                        </a>
                        <a href="workbook/pages/#chap4">
                            <button>
                                <!-- <h6>chapter 4:</h6>  -->
                                <p>4. Mistakes, Guilt, and Dealing with Others</p>
                            </button>
                        </a>
                        <a href="workbook/pages/#chap5">
                            <button>
                                <!-- <h6>chapter 5:</h6>  -->
                                <p>5. Looking in the Mirror</p>
                            </button>
                        </a>
                        <a href="workbook/pages/#chap6">
                            <button>
                                <!-- <h6>chapter 6:</h6> -->
                                <p>6. Becoming My Own Biggest Cheerleader</p>
                            </button>
                        </a>
                    </div>
                </div>
<!-- part 2 -->
                <div class="part">
                    <div class="navbar">
                        <div class="container-fluid">
                            <div class="navbar-left">
                                <h5><a href="workbook/pages/#part2">Part 2:</a></h5>
                            </div>
                            <div class="navbar-right">
                                <a href="workbook/pages/#part2"><p class="title">The We of Life -- <br>Finding the Right Match</p></a>
                            </div>
                        </div>
                    </div>
               
                    <div class="btn-group btn-group-vertical">
                        <a href="workbook/pages/#chap7">
                            <button>
                                <!-- <h6>chapter 7:</h6> -->
                                <p>7. Red Flags/ Blue Flags</p>
                            </button>
                        </a>
                        <a href="workbook/pages/#chap8">
                            <button>
                                <!-- <h6>chapter 8: </h6> -->
                                <p>8. Taking Inventory</p>
                            </button>
                        </a>
                        <a href="workbook/pages/#chap9">
                            <button>
                                <!-- <h6>chapter 9:</h6> -->
                                <p>9. My Own Custom Toolbox</p>
                            </button>
                        </a>
                        <a href="workbook/pages/#addendum">
                            <button>
                                <!-- <h6>chapter 9:</h6> -->
                                <p>Addendum</p>
                            </button>
                        </a>
                    </div>
                </div>

            </div>
        </nav>
    </div>

 <!-- workbook -->
    <div class="col">
        <app-lagos-workbook class="window"></app-lagos-workbook>
    </div>

</div>
    

